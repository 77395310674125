/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Modal, Button, Select, Input } from "antd";
import { isEmpty, truncate, size, set } from "lodash";
import { RNS3 } from "react-native-aws3";
import styled from "styled-components";
import CommonTable from "../Common/Table";
import { Button as ButtonUpload } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  FilterGrid,
  FilterTitleWrapper,
  StyledButton,
  StyledInput,
  LabelInput,
  ErrorText,
  IconContainer,
  StyledDiv,
  RightGridItem,
  LeftGridItem,
  StyledDateTimePicker,
  useStyles,
  ImageContainer,
  DeleteMedia,
} from "./styles";
import CommonLoader from "../Common/Loader";
import CommonDropdown from "../Common/Dropdown";
import CommonDatePicker from "../Common/DatePicker";
import Paginator from "../Common/Paginator";
import {
  formattedValue,
  snakeToUpperCase,
  isValidImageUploadObject,
  dateFormating,
} from "../../util/helper";
import {
  GetBannerListApi,
  CreateBannerApi,
  UpdateBannerApi,
  DeleteBannerApi,
} from "../../api/banner";
import { GetUploadS3ImageApi } from "../../api/uploadImageS3";
import {
  getBannerImageUrl,
  getImageWithBannerCreds,
  s3Config,
} from "../../util/custom";
import Image from "../Image";
import { PreviewImage } from "../Image/styles";
import {
  BulkDeleteUserUpdate,
  CreateGeneralUpdate,
  DeleteGeneralUpdate,
  GetGeneralUpdate,
  GetRandomUpdateCategory,
  UpdateGeneralUpdate,
  randomGeneralUserUpdateApi,
} from "../../api/getUpdateList";
import {
  DeleteGenralUpdateURL,
  UpdateGeneralUpdateURL,
} from "../../api/apiConfig";

const StyleModal = styled(Modal)`
  .ant-modal-header {
    background: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.dark};
  }

  .ant-modal-body {
    background: ${(props) => props.theme.dark};
  }

  .ant-modal-title {
    color: ${(props) => props.theme.text};
  }
  .ant-modal-footer {
    background: ${(props) => props.theme.dark};
  }
  .ant-modal-close-icon {
    color: ${(props) => props.theme.text};
  }

  .ant-modal .sc-AzgDb .jcGGxa {
    padding: 0px;
  }
`;

const GeneralUpdate = () => {
  const auth = useSelector((state) => state.auth);
  const maxTitleCharacters = 25;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [randomUserUpdateModal, setRandomUserUpdateModal] = useState(false)
  const [randomCategory, setRandomCategory] = useState({
    key: 'category',
    data: [{
      id: "",
      title: "",
      selected: false,
  }]})

  console.log("randomCategory - ",randomCategory)
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const { Option } = Select;
  const [redirectionParams, setRedirectionParams] = useState([
    { key: "", value: "" },
  ]);
  const [redirectionParams2, setRedirectionParams2] = useState([
    { key: "", value: "" },
  ]);
  const [filters, setFilters] = useState({
    page: currentPage,
    page_limit: pageSize,
    is_active: "",
    is_deleted: "false",
    target_users: "",
    category: ""
  });
  const initialRandomGeneralUserUpdate = {
    image: {},
    file: {
      fileBase64: "",
      name: ""
    },
    cta1: {
      text: "",
      redirection_url: "",
      redirection_params: [{ key: "", value: "" }],
      redirection_type: "",
    },
    cta2: {
      text: "",
      redirection_url: "",
      redirection_params: [{ key: "", value: "" }],
      redirection_type: "",
    },
  }
  const initialRandomGeneralErrors = {
    image: "",
    file: "",
    cta1: {
      text: "",
      redirection_url: "",
      redirection_params: [{ key: "", value: "" }],
      redirection_type: "",
    },
    cta2: {
      text: "",
      redirection_url: "",
      redirection_params: [{ key: "", value: "" }],
      redirection_type: "",
    },
  }
  const [randomGeneralUserUpdate, setRandomGeneralUserUpdate] = useState({ ...initialRandomGeneralUserUpdate })
  const [randomGeneralErrors, setRandomGeneralErrors] = useState({ ...initialRandomGeneralErrors })

  const fileuploadref = useRef();


  const defaultBanner = {
    text: "",
    type: "",
    banner_image: {},
    cta1: {
      text: "",
      redirection_url: "",
      redirection_params: [{ key: "", value: "" }],
      redirection_type: "",
    },
    cta2: {
      text: "",
      redirection_url: "",
      redirection_params: [{ key: "", value: "" }],
      redirection_type: "",
    },
    is_active: "",
  };

  const defaultIsUpdatedObject = {
    startTime: false,
    endTime: false,
    banner_image: false,
  };

  const [currentBanner, setCurrentBanner] = useState(defaultBanner);
  const [errors, setErrors] = useState({});

  const [updateBanner, setUpdateBanner] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  const [isUpdated, setIsUpdated] = useState(defaultIsUpdatedObject);

  const getBannerList = (params) => {
    console.log("🚀 ~ file: index.jsx:154 ~ getBannerList ~ params:", params);
    setLoading(true);
    GetGeneralUpdate(params)
      .then((res) => {
        console.log("🚀 ~ file: index.jsx:151 ~ .then ~ res:", res);
        console.log(res, params);
        let success = false;
        if (res.status === 200) {
          success = true;
          const apiData = res.data;
          console.log("🚀 ~ file: index.jsx:154 ~ .then ~ apiData:",params,  apiData);
          setData(apiData.results);
          // setClientCodeList(apiData.results.client_code);
          setCurrentPage(apiData.page);
          setPageSize(apiData.page_limit);
          setTotal(apiData.total_count);
          setFilters(params);
          setLoading(false);
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        console.log("🚀 ~ file: index.jsx:172 ~ getBannerList ~ error:", error);
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };
  const renderIsPinned = (data) => (
    <div>
      {data.is_deleted === false && data.status === "published" ? (
        <IconContainer>
          <PushpinFilled
            style={{ fontSize: "22px" }}
            onClick={() => showPinPostModal(data)}
          />
        </IconContainer>
      ) : null}
    </div>
  );

  const deleteBanner = (id) => {
    swal({
      title: "Are you sure you want to delete this Update ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        setLoading(true);
        const deleteParams = {
          id,
        };
        console.log(
          "🚀 ~ file: index.jsx:209 ~ deleteBanner ~ deleteParams:",
          deleteParams
        );
        DeleteGeneralUpdate(deleteParams)
          .then((res) => {
            let apiSuccess = false;
            setLoading(false);
            if (res.status === 200) {
              if ((res.data || {}).success) {
                apiSuccess = true;
                getBannerList(filters);
                swal("success!", "Successfully Deleted Update", "success");
              }
            }
            if (!apiSuccess) {
              setLoading(false);
              swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
            }
          })
          .catch((error) => {
            console.log(
              "🚀 ~ file: index.jsx:226 ~ deleteBanner ~ error:",
              error
            );
            setLoading(false);
            let errorMessage = "Failed to delete Banner";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          });
      }
    });
  };

  const showCreateBannerModal = () => {
    setRedirectionParams([{ key: "", value: "" }]);
    setRedirectionParams2([{ key: "", value: "" }]);
    setShowCreateModal(true);
  };

  const handleCreateBannerModalCancel = () => {
    setShowCreateModal(false);
    setCurrentBanner(defaultBanner);
    setErrors({});
  };

  const showUpdateBannerModal = (bannerObj) => {
    const updateObject = Object.create(defaultBanner);
    const arr = [];
    updateObject.id = bannerObj.id;
    updateObject.text = bannerObj.text;
    updateObject.type = bannerObj.type;
    updateObject.is_active = bannerObj.is_active ? "true" : "false";
    updateObject.target_users = bannerObj.target_users;
    updateObject.mobile_number = bannerObj?.mobile_number || "";
    updateObject.category = bannerObj?.category || "";

    if (bannerObj.cta_1 != null) {
      updateObject.cta1 = {
        text: bannerObj.cta_1.text,
        redirection_url: bannerObj.cta_1.redirection_url,
        redirection_params: bannerObj.cta_1.redirection_params,
        redirection_type: bannerObj.cta_1.redirection_type,
      };
      if (bannerObj?.cta_1?.redirection_params != "{}") {
        const newArr = Object.keys(bannerObj?.cta_1?.redirection_params).map(
          (item) => {
            const obj = {
              key: item,
              value: bannerObj?.cta_1?.redirection_params[item],
            };
            return obj;
          }
        );
        setRedirectionParams(newArr);
      }
    }

    if (bannerObj.cta_2 != null) {
      updateObject.cta2 = {
        text: bannerObj.cta_2.text,
        redirection_url: bannerObj.cta_2.redirection_url,
        redirection_params: bannerObj.cta_2.redirection_params,
        redirection_type: bannerObj.cta_2.redirection_type,
      };
      if (bannerObj?.cta_2?.redirection_params != "{}") {
        const newArr = Object.keys(bannerObj?.cta_2?.redirection_params).map(
          (item) => {
            const obj = {
              key: item,
              value: bannerObj?.cta_2?.redirection_params[item],
            };
            return obj;
          }
        );
        setRedirectionParams2(newArr);
      }
    }
    // updateObject.ui_version = bannerObj.ui_version;
    updateObject.banner_image = bannerObj.image;
    console.log(
      "🚀 ~ file: index.jsx:370 ~ showUpdateBannerModal ~ updateObject:",
      updateObject
    );
    setUpdateBanner(updateObject);
    setShowUpdateModal(true);
  };

  const handleUpdateModalCancel = () => {
    setShowUpdateModal(false);
    setUpdateBanner({});
    setUpdateErrors({});
    setIsUpdated(defaultIsUpdatedObject);
  };

  const renderEdit = (item) => (
    <IconContainer>
      <EditIcon onClick={() => showUpdateBannerModal(item)} />
    </IconContainer>
  );

  const generateBannerParams = (bannerObj, image, action) => {
    console.log(
      "🚀 ~ file: index.jsx:349 ~ generateBannerParams ~ bannerObj:",
      bannerObj
    );
    // eslint-disable-next-line prefer-const
    let obj = {};
    redirectionParams.forEach((item, index) => {
      if (item.key !== "") {
        obj[item.key] = item.value;
      }
    });
    if (JSON.stringify(obj) === "{}") {
      obj = {};
    }
    let obj2 = {};
    redirectionParams2.forEach((item, index) => {
      if (item.key !== "") {
        obj2[item.key] = item.value;
      }
    });
    if (JSON.stringify(obj2) === "{}") {
      obj2 = {};
    }
    // console.log(redirectionParams, obj.toString(), JSON.stringify(obj));

    const paramsObj = {
      text: bannerObj.text,
      type: bannerObj.type,
      // type: bannerObj.type,
      // cta_1: { ...bannerObj.cta1, redirection_params: obj },
      // cta_2: { ...bannerObj.cta2, redirection_params: obj2 },
      target_users: bannerObj.target_users,
      category: bannerObj?.category,
      image: image,
      is_active: bannerObj.is_active,
    };

    if(bannerObj?.mobile_number){
      paramsObj.mobile_number = bannerObj?.mobile_number
    }

    // if (action != "update") {
    //   paramsObj["is_active"] = bannerObj.is_active;
    // }

    if (bannerObj?.cta1?.text != "" && bannerObj?.cta1?.text != undefined) {
      paramsObj["cta_1"] = { ...bannerObj.cta1, redirection_params: obj };
    }

    if (
      bannerObj?.cta2?.text != "" &&
      bannerObj?.cta2?.text != undefined &&
      bannerObj?.cta2?.text != null
    ) {
      paramsObj["cta_2"] = { ...bannerObj.cta2, redirection_params: obj2 };
    }
    if (action === "update") {
      paramsObj.id = bannerObj.id;
    }
    console.log(
      "🚀 ~ file: index.jsx:384 ~ generateBannerParams ~ paramsObj:",
      paramsObj
    );

    return paramsObj;
  };

  const createBanner = (image) => {
    const params = generateBannerParams(currentBanner, image, "create");
    console.log("🚀 ~ file: index.jsx:372 ~ createBanner ~ params:", params);
    setLoading(true);
    // console.log('Params ', params);
    CreateGeneralUpdate(params)
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleCreateBannerModalCancel();
            getBannerList(filters);
            swal("success!", "Successfully Created Update", "success");
          }
        }
        if (!success) {
          let err = ((res.data || {}).data || {}).error_desc;
          if (JSON.stringify(err).includes("duplicate")) {
            err = "This position is already exist.";
          }
          swal("Oops!", err, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Update Creation Failed";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
            if (
              JSON.stringify(error.response.data.error_desc).includes(
                "duplicate"
              )
            ) {
              errorMessage = "This position is already exist.";
            }
          }
        }
        swal("OOPS!", errorMessage, "error");
      });
  };

  const updateBannerApiCall = (image, isActiveParams = null) => {
    console.log(
      "🚀 ~ file: index.jsx:488 ~ updateBannerApiCall ~ isActiveParams:",
      isActiveParams
    );
    const updateParams =
      isActiveParams !== null
        ? isActiveParams
        : generateBannerParams(updateBanner, image, "update");

    console.log(
      "🚀 ~ file: index.jsx:493 ~ updateBannerApiCall ~ updateParams:",
      updateParams
    );

    setLoading(true);
    // console.log('Params ', updateParams);
    UpdateGeneralUpdate(updateParams)
      .then((res) => {
        setLoading(false);
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleUpdateModalCancel();
            getBannerList(filters);
            swal("Yayy!", "Successfully Updated General Update", "success");
          }
        }
        if (!success) {
          let err = ((res.data || {}).data || {}).error_desc;
          if (JSON.stringify(err).includes("duplicate")) {
            err = "This position is already exist.";
          }
          swal("Oops!", err, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to Update General Update";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
            if (
              JSON.stringify(error.response.data.error_desc).includes(
                "duplicate"
              )
            ) {
              errorMessage = "This position is already exist.";
            }
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };
  const createTags = (tags = []) => {
    const tagArray = [];
    if (tags != null) {
      tags.map((tag) => {
        tagArray.push(<StyledTag key={tag.id}>{tag.title}</StyledTag>);
        return 0;
      });
    }
    return tagArray;
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 10,
      render: (id) => <>{id}</>,
    },
    {
      title: "Title",
      dataIndex: "text",
      key: "text",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Target Users",
      dataIndex: "target_users",
      key: "target_users",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   textWrap: "word-break",
    //   width: 110,
    //   align: "center",
    //   render: (value) => {
    //     return (
    //       <div style={{ width: "300px" }}>
    //         <PreviewImage src={getBannerImageUrl(value)} width="300px" />
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 110,
      align: "center",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 110,
      align: "center",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      width: 180,
      align: "center",
      render: (value, item) => {
        return value ? (
          // eslint-disable-next-line
          <div
            onClick={(e) => {
              updateBannerApiCall(null, { id: item.id, is_active: false });
            }}
          >
            <CheckCircleIcon />
          </div>
        ) : (
          // eslint-disable-next-line
          <div
            onClick={(e) => {
              updateBannerApiCall(null, { id: item.id, is_active: true });
            }}
          >
            <CheckBoxOutlineBlankIcon />
          </div>
        );
      },
      // <input
      // type="checkbox"
      // checked={value}
      // onChange={() => {
      //     const obj = item;
      //     obj.is_active = !obj.is_active;
      //     console.log('Id - ', obj);
      //     updateBannerApiCall(obj);
      // }}
      // />
    },
    // {
    //     title: 'CTA Text',
    //     dataIndex: 'cta_text1',
    //     key: 'cta_text1',
    //     textWrap: 'word-break',
    //     width: 110,
    //     align: 'center',
    //     render: (value) => <p>{formattedValue(value)}</p>,
    // },

    // {
    //     title: 'View Registrations',
    //     dataIndex: 'id',
    //     key: 'id',
    //     align: 'center',
    //     render: (item) => (
    //         <IconContainer>
    //             <NavLink to={`/banner/${item}`}>
    //                 <VisibilityIcon />
    //             </NavLink>
    //         </IconContainer>
    //     ),
    // },
  ];

  if (!JSON.parse(filters.is_deleted || false)) {
    tableColumns.push({
      title: "Edit",
      dataIndex: "id",
      key: "id",
      render: (text, item) => renderEdit(item),
      align: "center",
    });
    if(filters?.category !== ""){
      tableColumns.push({
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, item) =>
        item.is_deleted === false ? (
          <IconContainer>
            <DeleteIcon onClick={() => deleteBanner(item.id)} />
          </IconContainer>
        ) : null,
    });}
  }

  const createOptionList = (list, type) => {
    const optionArray = [];
    // eslint-disable-next-line array-callback-return
    list.map((item) => {
      type == "create" && item.id == "selected" ? null :
      optionArray.push(
        <Option key={item.id} value={item.id}>
          {item.title}
        </Option>
      );
    });

    return optionArray;
  };

  const RedirectionType = {
    key: "redirection_type",
    data: [
      {
        id: "in_app",
        title: "In App",
        selected: false,
      },
      {
        id: "external_link",
        title: "External Link",
        selected: false,
      },
    ],
  };

  const handleSelectChange = (value, key, action) => {
    if(action === "random"){
      if (key === "redirection_type-1") {
        setRandomGeneralUserUpdate({
          ...randomGeneralUserUpdate,
          cta1: {
            text: randomGeneralUserUpdate.cta1.text,
            redirection_type: value,
            redirection_url: "",
          },
        });
      } else if (key === "redirection_type-2") {
        setRedirectionParams2([{ key: "", value: "" }]);
        setRandomGeneralUserUpdate({
          ...randomGeneralUserUpdate,
          cta2: {
            text: randomGeneralUserUpdate.cta2.text,
            redirection_type: value,
            redirection_url: "",
          },
        });
      } else if (key === "redirection_in_app-1") {
        if (value === "HomeSavingChallenge") {
          setRedirectionParams([{ key: "savingChallenge", value: "true" }]);
          value = "Home";
        }else{ setRedirectionParams([{ key: "", value: "" }]);}
        setRandomGeneralUserUpdate({
          ...randomGeneralUserUpdate,
          cta1: { ...randomGeneralUserUpdate.cta1, redirection_url: value },
        });
      } else if (key === "redirection_in_app-2") {
        if (value === "HomeSavingChallenge") {
          setRedirectionParams2([{ key: "savingChallenge", value: "true" }]);
          value = "Home";
        }else{ setRedirectionParams2([{ key: "", value: "" }]);}
        setRandomGeneralUserUpdate({
          ...randomGeneralUserUpdate,
          cta2: { ...randomGeneralUserUpdate.cta2, redirection_url: value },
        });
      }
    }

    else if (action === "create") {
      if (key === "redirection_type-1") {
        setRedirectionParams([{ key: "", value: "" }]);
        setCurrentBanner({
          ...currentBanner,
          cta1: {
            text: currentBanner.cta1.text,
            redirection_type: value,
            redirection_url: "",
          },
        });
      } else if (key === "redirection_type-2") {
        setRedirectionParams2([{ key: "", value: "" }]);
        setCurrentBanner({
          ...currentBanner,
          cta2: {
            text: currentBanner.cta2.text,
            redirection_type: value,
            redirection_url: "",
          },
        });
      } else if (key === "redirection_in_app-1") {
        if (value === "HomeSavingChallenge") {
          setRedirectionParams([{ key: "savingChallenge", value: "true" }]);
          value = "Home";
        }else{ setRedirectionParams([{ key: "", value: "" }]);}
        setCurrentBanner({
          ...currentBanner,
          cta1: { ...currentBanner.cta1, redirection_url: value },
        });
      } else if (key === "redirection_in_app-2") {
        if (value === "HomeSavingChallenge") {
          setRedirectionParams2([{ key: "savingChallenge", value: "true" }]);
          value = "Home";
        }else{ setRedirectionParams2([{ key: "", value: "" }]);}
        setCurrentBanner({
          ...currentBanner,
          cta2: { ...currentBanner.cta2, redirection_url: value },
        });
      } else if (key === "is_active") {
        setCurrentBanner({ ...currentBanner, is_active: value });
      } else if (key === "placement") {
        setCurrentBanner({ ...currentBanner, placement: value });
      } else if (key === "sub_placement") {
        setCurrentBanner({ ...currentBanner, sub_placement: value });
      } else if (key === "target_users") {
        setCurrentBanner({ ...currentBanner, target_users: value });
      } else if (key === "type") {
        setCurrentBanner({ ...currentBanner, type: value });
      } else if (key === "ui_version") {
        setCurrentBanner({ ...currentBanner, ui_version: value });
      }
    } else if (action === "update") {
      if (key === "redirection_type-1") {
        setRedirectionParams([{ key: "", value: "" }]);
        setUpdateBanner({
          ...updateBanner,
          cta1: {
            text: updateBanner?.cta1?.text,
            redirection_type: value,
            redirection_url: "",
          },
        });
      } else if (key === "redirection_type-2") {
        setRedirectionParams2([{ key: "", value: "" }]);
        setUpdateBanner({
          ...updateBanner,
          cta2: {
            text: updateBanner?.cta2?.text,
            redirection_type: value,
            redirection_url: "",
          },
        });
      } else if (key === "redirection_in_app-1") {
        if (value === "HomeSavingChallenge") {
          setRedirectionParams([{ key: "savingChallenge", value: "true" }]);
          value = "Home";
        }else{ setRedirectionParams([{ key: "", value: "" }]);}
        setUpdateBanner({
          ...updateBanner,
          cta1: { ...updateBanner.cta1, redirection_url: value },
        });
      } else if (key === "redirection_in_app-2") {
        if (value === "HomeSavingChallenge") {
          setRedirectionParams2([{ key: "savingChallenge", value: "true" }]);
          value = "Home";
        }else{ setRedirectionParams2([{ key: "", value: "" }]);}
        setUpdateBanner({
          ...updateBanner,
          cta2: { ...updateBanner.cta2, redirection_url: value },
        });
      } else if (key === "is_active") {
        setUpdateBanner({ ...updateBanner, is_active: value });
      } else if (key === "placement") {
        setUpdateBanner({ ...updateBanner, placement: value });
      } else if (key === "sub_placement") {
        setUpdateBanner({ ...updateBanner, sub_placement: value });
      } else if (key === "target_users") {
        setUpdateBanner({ ...updateBanner, target_users: value });
      } else if (key === "type") {
        setUpdateBanner({ ...updateBanner, type: value });
      } else if (key === "ui_version") {
        setUpdateBanner({ ...updateBanner, ui_version: value });
      }
    }
  };

  const onFileChange = (banner, action) => {
    try {
      if (action === "create") {
        if (!banner) {
          setCurrentBanner({ ...currentBanner, banner_image: {} });
          return;
        }
        const file = banner.target?.files[0];
        setCurrentBanner({
          ...currentBanner,
          banner_image: file,
        });
      } else if (action === "update") {
        setIsUpdated({ ...isUpdated, banner_image: true });
        if (!banner) {
          setUpdateBanner({ ...updateBanner, banner_image: {} });
          return;
        }
        const file = banner.target?.files[0];
        const check = {
          ...updateBanner,
          banner_image: file,
        };
        console.log("🚀 ~ file: index.jsx:833 ~ onFileChange ~ check:", check);

        setUpdateBanner({
          ...updateBanner,
          banner_image: file,
        });
      } else if (action === "randomUserUpdate") {
        if (!banner) {
          setRandomGeneralUserUpdate({ ...randomGeneralUserUpdate, image: {} });
          return;
        }
        const file = banner.target?.files[0];
        setRandomGeneralUserUpdate({
          ...randomGeneralUserUpdate,
          image: file,
        });
        banner.target.value = "";
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:839 ~ onFileChange ~ err:", err);
    }
  };

  useEffect(() => {
    console.log(
      "🚀 ~ file: index.jsx:844 ~ useEffect ~ updateBanner:",
      updateBanner,
      updateBanner.cta1
    );
  }, [updateBanner]);

  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getRandomCategory()
    getBannerList(filters);
  }, [currentPage, pageSize]);

  const getRandomCategory = () => {
    GetRandomUpdateCategory().then((res)=>{
      const categoryArr = res?.data?.data || res?.data
      const obj = {
        key: "category",
        data: categoryArr?.length < 1 ? [{
          id: "",
          title: "",
          selected: false,
      }] : categoryArr,
      }
      setRandomCategory(obj)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const validateFields = (action) => {
    const validationErrors = {};
    // let isParamErrors = false;
    let bannerObj = {};

    if (action === "create") {
      bannerObj = currentBanner;
    } else if (action === "update") {
      bannerObj = updateBanner;
    }
    if (bannerObj.text.length === 0) {
      validationErrors.text = "Required Text";
    }

    // if (bannerObj.name.length === 0) {
    //   validationErrors.name = "Required Name";
    // }

    if (bannerObj.type.length === 0) {
      validationErrors.type = "Required Type";
    }
    console.log(
      "🚀 ~ file: index.jsx:889 ~ validateFields ~ bannerObj?.cta1?.text?.length != 0:",
      bannerObj?.cta1?.text
    );

    if (bannerObj?.cta1?.text?.length == 0) {
      validationErrors.cta1 = { text: "Required CTA" };
      console.log(
        "🚀 ~ file: index.jsx:895 ~ validateFields ~ validationErrors:",
        validationErrors
      );
    } else {
      if (bannerObj?.cta1?.redirection_type?.length == 0) {
        validationErrors.cta1 = {
          ...validationErrors.cta1,
          redirection_type: "Required Redirection Type",
        };
      }

      if (bannerObj?.cta1?.redirection_in_app?.length == 0) {
        validationErrors.cta1 = {
          ...validationErrors.cta1,
          redirection_in_app: "Required Redirection In App",
        };
      }
      if (bannerObj?.cta1?.redirection_url?.length == 0) {
        validationErrors.cta1 = {
          ...validationErrors.cta1,
          redirection_url: "Required Redirection URL",
        };
      }
    }

    // if (bannerObj?.cta2?.text?.length == 0) {
    //   validationErrors.cta2 = { text: "Required CTA" };
    //   console.log(
    //     "🚀 ~ file: index.jsx:895 ~ validateFields ~ validationErrors:",
    //     validationErrors
    //   );
    // } else {
    //   if (bannerObj?.cta2?.redirection_type?.length == 0) {
    //     validationErrors.cta2 = {
    //       ...validationErrors.cta2,
    //       redirection_type: "Required Redirection Type",
    //     };
    //   }
    //   if (bannerObj?.cta2?.redirection_url?.length == 0) {
    //     validationErrors.cta2 = {
    //       ...validationErrors.cta2,
    //       redirection_url: "Required Redirection URL",
    //     };
    //   }
    // }


    if (size(bannerObj.is_active) === 0) {
      validationErrors.is_active = "Required Is Active";
    }

    if (size(bannerObj.target_users) === 0) {
      validationErrors.target_users = "Required Target User";
    }

    if (size(bannerObj.target_users) !== 0 && bannerObj.target_users === "selected" && size(bannerObj.mobile_number) === 0) {
      validationErrors.mobile_number = "Required Mobile Number";
    }

    // if (action === "create" || isUpdated.banner_image) {
    //   if (isValidImageUploadObject(bannerObj.banner_image)) {
    //     if (
    //       bannerObj.banner_image.size &&
    //       bannerObj.banner_image.size / 1048576 > 10
    //     ) {
    //       validationErrors.banner_image =
    //         "Please upload minimum file size less than 10mb";
    //     }
    //   } else {
    //     // validationErrors.banner_image = "Required Image";
    //   }
    // }

    // if (!isParamErrors && Object.keys(validationErrors).length === 1) {
    //     return {};
    // }
    return validationErrors;
  };

  useEffect(() => {
    console.log("🚀 ~ file: index.jsx:930 ~ useEffect ~ errors:", errors);
  }, [errors]);

  const handleCreateBannerSubmit = async (banner) => {
    banner.preventDefault();
    const validationErrors = validateFields("create");
    setErrors(validationErrors);
    if (true) {
      console.log(
        "🚀 ~ file: index.jsx:840 ~ handleCreateBannerSubmit ~ true:",
        true
      );
      console.log(
        "🚀 ~ file: index.jsx:846 ~ handleCreateBannerSubmit ~ isValidImageUploadObject(currentBanner.banner_image):",
        isValidImageUploadObject(currentBanner.banner_image)
      );

      // if (currentBanner.banner_image && isValidImageUploadObject(currentBanner.banner_image)) {
      //   let obj = {};
      //   console.log(
      //     "🚀 ~ file: index.jsx:842 ~ handleCreateBannerSubmit ~ obj:",
      //     obj
      //   );

      //   try {
      //     const createTimeNow = new Date();
      //     let keyPrefix = `icons/generalUpdates/${createTimeNow.toISOString()}}`;
      //     keyPrefix += "-";
      //     const config = { ...s3Config, keyPrefix };
      //     if (process.env.REACT_APP_AWS_ACL) {
      //       config.acl = process.env.REACT_APP_AWS_ACL;
      //     }
      //     const banner_image = currentBanner.banner_image;
      //     console.log(
      //       "🚀 ~ file: index.jsx:848 ~ handleCreateBannerSubmit ~ banner_image:",
      //       banner_image
      //     );
      //     RNS3.put(banner_image, config).then((res) => {
      //       const image = getImageWithBannerCreds({
      //         data: (res.body || {}).postResponse,
      //       });
      //       createBanner(image);
      //     });
      //   } catch (err) {
      //     console.log("🚀 ~ file: index.jsx:950 ~ err:", err);
      //   }
      // }else{
      //   if(JSON.stringify(validationErrors) == "{}"){
      //     createBanner(null);
      //   }
      // }
      if(JSON.stringify(validationErrors) == "{}"){
        createBanner(null);
      }
    }
  };

  const handleUpdateBannerSubmit = (banner) => {
    banner.preventDefault();
    const validationUpdateErrors = validateFields("update");
    setUpdateErrors(validationUpdateErrors);
    if (true) {
      if (
        isUpdated.banner_image &&
        isValidImageUploadObject(updateBanner.banner_image)
      ) {
        let obj = {};

        try {
          const updateTimeNow = new Date();
          let keyPrefix = `banner/${auth.user_id
            }/image/${updateTimeNow.toISOString()}`;
          keyPrefix += "-"+ updateBanner.banner_image.name;
          let reader = new FileReader();
          reader.readAsDataURL(updateBanner.banner_image);
          reader.onload = function () {
            const base64img = reader.result.split(',')[1];
            const params = {  
              filename: keyPrefix,
              encodedimg: base64img,
            }
            GetUploadS3ImageApi(params)
              .then((res) => {
                if (res.status === 200) {
                  if (res.data) {
                    console.log("success in file upload", res.data);
                    const imgS3Param = {
                      location: res.data.data.url,
                      key: keyPrefix,
                    }
                    const image = getImageWithBannerCreds({
                      data: imgS3Param,
                    });
                    updateBannerApiCall(JSON.stringify(image));
                  }
                }
                else {
                  console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
                }
              })
              .catch((error) => {
                let errorMessage = "error in file upload";
                if (error.response) {
                  if (error.response.status === 400) {
                    errorMessage = (error.response.data || {}).error_desc;
                  }
                }
                console.log("error in file upload", errorMessage);
              });
          };
          reader.onerror = function (error) {
            console.log("Error in file reading: ", error);
          };

          // keyPrefix += "-";
          // const config = { ...s3Config, keyPrefix };
          // if (process.env.REACT_APP_AWS_ACL) {
          //   config.acl = process.env.REACT_APP_AWS_ACL;
          // }
          // const banner_image = updateBanner.banner_image;
          // RNS3.put(banner_image, config)
          //   .progress((progress) => console.log("progress", progress))
          //   .then((res) => {
          //     const image = getImageWithBannerCreds({
          //       data: (res.body || {}).postResponse,
          //     });
          //     updateBannerApiCall(image);
          //   });
        } catch (err) {
          console.log("🚀 ~ file: index.jsx:985 ~ Object.keys ~ err:", err);
        }
      } else {
        if(updateBanner.banner_image){
          updateBannerApiCall(updateBanner.banner_image);
        }else{
          updateBannerApiCall(null);
        }
      }
    }
  };

  const handleFilterChange = (filters) => {
    console.log(
      "🚀 ~ file: index.jsx:1040 ~ handleFilterChange ~ filters:",
      filters
    );
    getBannerList(filters);
  };

  const IsActive = {
    key: "is_active",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: false,
      },
    ],
  };

  const IsDeleted = {
    key: "is_deleted",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };

  const SubPlacement = {
    key: "sub_placement",
    data: [
      {
        id: "topScroll",
        title: "Top Scroll",
        selected: true,
      },
      {
        id: "playToWin",
        title: "Play To Win",
        selected: false,
      },
      {
        id: "openYourAccount",
        title: "Open Your Account",
        selected: false,
      },
      {
        id: "getLoans",
        title: "Get Loans",
        selected: false,
      },
      {
        id: "community",
        title: "Community",
        selected: false,
      },
      {
        id: "offersForYou",
        title: "Offers For You",
        selected: false,
      },
    ],
  };

  const Placement = {
    key: "placement",
    data: [
      {
        id: "Home",
        title: "Home",
        selected: true,
      },
      {
        id: "Community",
        title: "Community",
        selected: false,
      },
    ],
  };

  const TargetUsers = {
    key: "target_users",
    data: [
      {
        id: "all",
        title: "All",
        selected: false,
      },
      {
        id: "invested",
        title: "Invested",
        selected: false,
      },
      {
        id: "non_invested",
        title: "Non Invested",
        selected: false,
      },
      {
        id: "signed_up",
        title: "Signed Up",
        selected: false,
      },
      {
        id: "selected",
        title: "Selected",
        selected: false,
      },
    ],
  };
  const Type = {
    key: "type",
    data: [
      {
        id: "portfolio",
        title: "PortFolio",
        selected: false,
      },
    ],
  };


  const UIVersion = {
    key: "ui_version",
    data: [
      {
        id: "v1",
        title: "v1",
        selected: true,
      },
      {
        id: "v2",
        title: "v2",
        selected: false,
      },
    ],
  };

  const RedirectionInApp = {
    key: "redirection_in_app",
    data: [
      {
        id: "Home",
        title: "Home",
        selected: true,
      },
      {
        id: "MyMoney",
        title: "MyMoney",
        selected: false,
      },
      {
        id: "More",
        title: "More",
        selected: false,
      },
      {
        id: "Profile",
        title: "Profile",
        selected: true,
      },
      {
        id: "About",
        title: "About",
        selected: true,
      },
      {
        id: "Faq",
        title: "Faq",
        selected: false,
      },
      {
        id: "Portfolio",
        title: "Portfolio",
        selected: false,
      },
      {
        id: "Invest",
        title: "Invest",
        selected: true,
      },
      {
        id: "InvestMore",
        title: "Invest More",
        selected: false,
      },
      {
        id: "InvestSummary",
        title: "Invest Summary",
        selected: false,
      },
      {
        id: "Learn",
        title: "Learn",
        selected: true,
      },
      {
        id: "Transactions",
        title: "Transactions",
        selected: true,
      },
      {
        id: "TransactionDetails",
        title: "Transaction Details",
        selected: false,
      },
      {
        id: "Notifications",
        title: "Notifications",
        selected: false,
      },
      {
        id: "MoneyJourneyPosts",
        title: "Money Journey Posts",
        selected: true,
      },
      {
        id: "Support",
        title: "Support",
        selected: true,
      },
      {
        id: "Community",
        title: "Community",
        selected: false,
      },
      {
        id: "Rewards",
        title: "Rewards",
        selected: false,
      },
      {
        id: "InvestmentProfileIntroduction",
        title: "Investment Profile Introduction",
        selected: true,
      },
      {
        id: "InvestmentProfileDetails",
        title: "Investment Profile Details",
        selected: false,
      },
      {
        id: "AccountOpeningPage",
        title: "Account Opening Page",
        selected: false,
      },
      {
        id: "PrivacyPolicy",
        title: "Privacy Policy",
        selected: true,
      },
      {
        id: "GoalCalculator",
        title: "Goal Calculator",
        selected: false,
      },
      {
        id: "RetirementCalculator",
        title: "Retirement Calculator",
        selected: false,
      },
      {
        id: "TaxSavingCalculator",
        title: "Tax Saving Calculator",
        selected: true,
      },
      {
        id: "PostDetailedView",
        title: "Post Detailed View",
        selected: false,
      },
      {
        id: "InvestmentWithdraw",
        title: "Retirement Calculator",
        selected: false,
      },
      {
        id: "LoanLending",
        title: "Loan Lending",
        selected: false,
      },
      {
        id: "Home-calculator",
        title: "Home Calculator",
        selected: false,
      },
      {
        id: "Referral",
        title: "referral",
        selected: false,
      },
      {
        id: "OffersForYou",
        title: "Offers For You",
        selected: false,
      },
      {
        id: "DigitalGold",
        title: "Digital Gold",
        selected: false,
      },
      {
        id: "MyGold",
        title: "My Gold",
        selected: false,
      },
      {
        id: "RedeemGold",
        title: "Redeem Gold",
        selected: false,
      },
      {
        id: "IncomeScreen",
        title: "Income Screen",
        selected: false,
      },
      {
        id: "HomeSavingChallenge",
        title: "Saving Challenge",
        selected: false,
      },
      {
        id: "VisionBoard",
        title: "Vision Board",
        selected: false,
      },
      {
        id: "Rewards",
        title: "Spin The Wheel",
        selected: false,
      },
    ],
  };

  const sortTypeList = {
    key: "sort_type",
    data: [
      {
        id: "asc",
        title: "Asc",
        selected: false,
      },
      {
        id: "desc",
        title: "Desc",
        selected: true,
      },
    ],
  };

  const sortByList = {
    key: "sort_by",
    data: [
      {
        id: "position",
        title: "Position",
        selected: true,
      },
    ],
  };

  const addParamsFunc = () => {
    const newArr = [...redirectionParams];
    newArr.push({ key: "", value: "" });
    setRedirectionParams(newArr);
  };

  const removeParamsFunc = () => {
    const newArr = [...redirectionParams];
    if (newArr.length > 1) {
      setRedirectionParams(newArr);
      newArr.pop();
    }
  };
  const addParamsFunc2 = () => {
    const newArr = [...redirectionParams2];
    newArr.push({ key: "", value: "" });
    setRedirectionParams2(newArr);
  };

  const removeParamsFunc2 = () => {
    const newArr = [...redirectionParams2];
    if (newArr.length > 1) {
      setRedirectionParams2(newArr);
      newArr.pop();
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileInputChange = async (e) => {
    try {
      let file = e.target.files[0];
      const response = await getBase64(file);
      file["base64"] = await response;
      let fileBase64 = file["base64"].split(",")[1]
      setRandomGeneralUserUpdate({
        ...randomGeneralUserUpdate, file: {
          fileBase64,
          name: file.name
        }
      });
    } catch (err) {
      console.log("🚀 ~ handleFileInputChange ~ err:", err)
    }
  };

  const validateRandomGeneralUserUpdate = () => {
    const validationErrors = {};
    // if (!isValidImageUploadObject(randomGeneralUserUpdate.image)) {
    //   validationErrors.image = "Required Image";
    // }

    if (randomGeneralUserUpdate?.cta1?.text?.length == 0) {
      validationErrors.cta1 = { text: "Required CTA" };
      console.log(
        "🚀 ~ file: index.jsx:895 ~ validateFields ~ validationErrors:",
        validationErrors
      );
    } else {
      if (randomGeneralUserUpdate?.cta1?.redirection_type?.length == 0) {
        validationErrors.cta1 = {
          ...validationErrors.cta1,
          redirection_type: "Required Redirection Type",
        };
      }

      if (randomGeneralUserUpdate?.cta1?.redirection_in_app?.length == 0) {
        validationErrors.cta1 = {
          ...validationErrors.cta1,
          redirection_in_app: "Required Redirection In App",
        };
      }
      if (randomGeneralUserUpdate?.cta1?.redirection_url?.length == 0) {
        validationErrors.cta1 = {
          ...validationErrors.cta1,
          redirection_url: "Required Redirection URL",
        };
      }
    }

    if (randomGeneralUserUpdate.file.fileBase64 == '') {
      validationErrors.file = "Required File";
    }
    // setRandomGeneralErrors(validationErrors);
    return Object.keys(validationErrors).length > 0;
  }

  const handleCreateRandomUserUpdate = async (values) => {
    values.preventDefault();
    const validation = validateRandomGeneralUserUpdate();
    if (Object.keys(validation).length > 0) {
      return;
    } else {
      try {
        const createTimeNow = new Date();
        let keyPrefix = `icons/generalUpdates/${createTimeNow.toISOString()}}`;
        keyPrefix += "-";
        const config = { ...s3Config, keyPrefix };
        if (process.env.REACT_APP_AWS_ACL) {
          config.acl = process.env.REACT_APP_AWS_ACL;
        }
        // const banner_image = randomGeneralUserUpdate.image
        // RNS3.put(banner_image, config).then(async (res) => {
        //   const image = getImageWithBannerCreds({
        //     data: (res.body || {}).postResponse,
        //   });
          setLoading(true)
          let redirectionParamObj1 = {};
          redirectionParams.forEach((i)=>{
            redirectionParamObj1[`${i.key}`] = i.value
          })
          const params = {
            cta_1: {...randomGeneralUserUpdate.cta1, redirection_params: redirectionParamObj1},
            fileBase64: randomGeneralUserUpdate.file.fileBase64,
            category: randomGeneralUserUpdate?.category
          }
          if(randomGeneralUserUpdate.cta2.text != ""){
            let redirectionParamObj2 = {};
            redirectionParams2.forEach((i)=>{
              redirectionParamObj2[`${i.key}`] = i.value
            })
              params['cta_2'] = {...randomGeneralUserUpdate.cta2, redirection_params: redirectionParamObj2}
          }

          randomGeneralUserUpdateApi(params).then(res => res.data).then(response => {
            if (response.success) {
              swal("success!", response.message, "success");
              getBannerList(filters);
            }
            if (!response.success) {
              setLoading(false);
              swal("Oops!", ((response || {}).data || {}).error_desc, "error");
            }
          }).catch(error => {
            let errorMessage = "Failed to fetch data";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          }).finally(() => {
            setLoading(false)
            setRandomUserUpdateModal(false)
          })
        // })
      } catch (err) {
        console.log("🚀 ~ file: index.jsx:950 ~ err:", err);
      } finally {
      }
    }
  }

  const onRandomModalOpen = () => {
    setRandomGeneralUserUpdate({ ...initialRandomGeneralUserUpdate })
    setRandomGeneralErrors({ ...initialRandomGeneralErrors })
    setRandomUserUpdateModal(true)
  }

  const isRandomUpdateSubmitDisabled = validateRandomGeneralUserUpdate()

  const classes = useStyles();

  const onDeleteAll = () => {
    swal({
      text: "Are you sure you want to proceed?",
      buttons: [true, "Do it!"],
    }).then((willDoIt) => {
      if (willDoIt) {
        setLoading(true);
        BulkDeleteUserUpdate().then((response)=>{
          console.log("Error in try - ",response?.data?.success)
          if (response?.data?.success) {
            setLoading(false);
            swal("success!", "Deleted Succcessfully", "success");
            getBannerList(filters);
          }
          if (!response?.data?.success) {
            setLoading(false);
            swal("Oops!", ((response || {}).data || {}).error_desc, "error");
          }
        }).catch((error)=>{
          setLoading(false);
          let errorMessage = "Failed to delete";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
        })
      } else {}
    });
  }

  return (
    <Grid container spacing={2}>
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <StyledButton primary onClick={() => showCreateBannerModal()}>
          Create
        </StyledButton>
        <StyledButton primary onClick={onRandomModalOpen}>
          Random General User Update
        </StyledButton>
        {filters?.category !== "" ? 
        <StyledButton primary onClick={onDeleteAll}>
          Delete All
        </StyledButton> : null}
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
          <FilterTitleWrapper>Filters</FilterTitleWrapper>
          <CommonDropdown
            list={IsDeleted}
            title="Is Deleted"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={IsActive}
            title="Is Active"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={TargetUsers}
            title="Target Users"
            onChange={(filters) => {
              const newFilters = {...filters, category: ''}
              return handleFilterChange(newFilters)
            }}
            filters={filters}
          />
          {filters?.target_users == "selected" ?
          <CommonDropdown
            list={randomCategory}
            title="Category"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />: null}
          {/* <CommonDatePicker
            title="Created At LTE"
            type="inline"
            dateFormat="dd/MM/yyyy"
            filterKey="created_at_lte"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          /> */}
        </FilterGrid>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <CommonTable
            data={data}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: "overflow" }}
          />
          <Paginator
            totalRecords={total}
            pageLimit={pageSize}
            pageNeighbours={2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
      <Grid>

        {randomUserUpdateModal && (
          <StyleModal
            title="General User Update"
            visible={randomUserUpdateModal}
            onOk={(e) => handleCreateRandomUserUpdate(e)}
            onCancel={() => setRandomUserUpdateModal(false)}
            footer={[
              <Button
                disabled={isRandomUpdateSubmitDisabled}
                key="submit"
                type="primary"
                onClick={(e) => handleCreateRandomUserUpdate(e)}
                loading={loading}
              >
                Submit
              </Button>,
            ]}
          >
            <div>
            <StyledDiv>
              <LabelInput>Left CTA</LabelInput>
              <Input
                placeholder="cta-1"
                name="Cta-1"
                value={randomGeneralUserUpdate.cta1.text}
                onChange={(e) =>
                  setRandomGeneralUserUpdate({
                    ...randomGeneralUserUpdate,
                    cta1: {
                      ...randomGeneralUserUpdate.cta1,
                      text: e.target.value,
                    },
                  })
                }
              />
              {randomGeneralErrors?.cta1?.text && (
                <ErrorText>{randomGeneralErrors?.cta1?.text}</ErrorText>
              )}
            </StyledDiv>
            {randomGeneralUserUpdate.cta1.text != "" && (
              <StyledDiv>
                <LabelInput>Redirection Type</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_type-1", "random")
                  }
                  value={randomGeneralUserUpdate.cta1.redirection_type}
                >
                  {RedirectionType && createOptionList(RedirectionType.data)}
                </Select>
                {randomGeneralErrors?.cta1?.redirection_type && (
                  <ErrorText>{randomGeneralErrors?.cta1?.redirection_type}</ErrorText>
                )}
              </StyledDiv>
            )}
            {randomGeneralUserUpdate?.cta1?.redirection_type === "in_app" && (
              <StyledDiv>
                <LabelInput>Redirection In App</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_in_app-1", "random")
                  }
                  value={randomGeneralUserUpdate.cta1.redirection_url}
                >
                  {RedirectionInApp && createOptionList(RedirectionInApp.data)}
                </Select>
                {randomGeneralErrors?.cta1?.redirection_url && (
                  <ErrorText>{randomGeneralErrors?.cta1?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {randomGeneralUserUpdate?.cta1?.redirection_type === "external_link" && (
              <StyledDiv>
                <LabelInput>Redirection URL</LabelInput>
                <Input
                  placeholder="Redirection URL"
                  name="redirection_url"
                  value={randomGeneralUserUpdate.cta1.redirection_url}
                  onChange={(e) =>
                    setRandomGeneralUserUpdate({
                      ...randomGeneralUserUpdate,
                      cta1: {
                        ...randomGeneralUserUpdate.cta1,
                        redirection_url: e.target.value,
                      },
                    })
                  }
                />
                {randomGeneralErrors?.cta1?.redirection_url && (
                  <ErrorText>{randomGeneralErrors?.cta1?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {randomGeneralUserUpdate?.cta1?.redirection_type === "in_app" && (
              <LabelInput>Redirection Params</LabelInput>
            )}
            {randomGeneralUserUpdate?.cta1?.redirection_type === "in_app" &&
              redirectionParams.map((item, index) => (
                <Grid container>
                  <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Key {index + 1}</LabelInput>
                      <Input
                        placeholder={`key ${index + 1}`}
                        name="key"
                        value={item.key}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].key = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {randomGeneralErrors.redirection_params && (
                                        <ErrorText>
                                            {randomGeneralErrors.redirection_params[index]?.key}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </LeftGridItem>
                  <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Value {index + 1}</LabelInput>
                      <Input
                        placeholder={`value ${index + 1}`}
                        name="value"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].value = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {randomGeneralErrors.redirection_params && (
                                        <ErrorText>
                                            {randomGeneralErrors.redirection_params[index]?.value}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </RightGridItem>
                </Grid>
              ))}
            {randomGeneralUserUpdate.cta1.redirection_type === "in_app" && (
              <div>
                <Button style={{ flex: 1 }} onClick={addParamsFunc}>
                  Add param
                </Button>
                <Button style={{ marginLeft: "3%" }} onClick={removeParamsFunc}>
                  Remove param
                </Button>
              </div>
            )}
            <StyledDiv>
              <LabelInput>Right CTA</LabelInput>
              <Input
                placeholder="cta-2"
                name="Cta-2"
                value={randomGeneralUserUpdate.cta2.text}
                onChange={(e) =>
                  setRandomGeneralUserUpdate({
                    ...randomGeneralUserUpdate,
                    cta2: {
                      ...randomGeneralUserUpdate.cta2,
                      text: e.target.value,
                    },
                  })
                }
              />
              {randomGeneralErrors?.cta2?.text && (
                <ErrorText>{randomGeneralErrors?.cta2?.text}</ErrorText>
              )}
            </StyledDiv>
            {randomGeneralUserUpdate.cta2.text != "" && (
              <StyledDiv>
                <LabelInput>Redirection Type</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_type-2", "random")
                  }
                  value={randomGeneralUserUpdate.cta2.redirection_type}
                >
                  {RedirectionType && createOptionList(RedirectionType.data)}
                </Select>
                {randomGeneralErrors?.cta2?.redirection_type && (
                  <ErrorText>{randomGeneralErrors?.cta2?.redirection_type}</ErrorText>
                )}
              </StyledDiv>
            )}
            {randomGeneralUserUpdate.cta2.redirection_type === "in_app" && (
              <StyledDiv>
                <LabelInput>Redirection In App</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_in_app-2", "random")
                  }
                  value={randomGeneralUserUpdate.cta2.redirection_url}
                >
                  {RedirectionInApp && createOptionList(RedirectionInApp.data)}
                </Select>
                {randomGeneralErrors?.cta2?.redirection_url && (
                  <ErrorText>{randomGeneralErrors?.cta2?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {randomGeneralUserUpdate.cta2.redirection_type === "external_link" && (
              <StyledDiv>
                <LabelInput>Redirection URL</LabelInput>
                <Input
                  placeholder="Redirection URL"
                  name="redirection_url"
                  value={randomGeneralUserUpdate.cta2.redirection_url}
                  onChange={(e) =>
                    setRandomGeneralUserUpdate({
                      ...randomGeneralUserUpdate,
                      cta2: {
                        ...randomGeneralUserUpdate.cta2,
                        redirection_url: e.target.value,
                      },
                    })
                  }
                />
                {randomGeneralErrors?.cta2?.redirection_url && (
                  <ErrorText>{randomGeneralErrors?.cta2?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {randomGeneralUserUpdate.cta2.redirection_type === "in_app" && (
              <LabelInput>Redirection Params</LabelInput>
            )}
            {randomGeneralUserUpdate.cta2.redirection_type === "in_app" &&
              redirectionParams2.map((item, index) => (
                <Grid container>
                  <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Key {index + 1}</LabelInput>
                      <Input
                        placeholder={`key ${index + 1}`}
                        name="key"
                        value={item.key}
                        onChange={(e) => {
                          const newArr = [...redirectionParams2];
                          newArr[index].key = e.target.value;
                          setRedirectionParams2(newArr);
                        }}
                      />
                      {/* {randomGeneralErrors.redirection_params && (
                                        <ErrorText>
                                            {randomGeneralErrors.redirection_params[index]?.key}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </LeftGridItem>
                  <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Value {index + 1}</LabelInput>
                      <Input
                        placeholder={`value ${index + 1}`}
                        name="value"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].value = e.target.value;
                          setRedirectionParams2(newArr);
                        }}
                      />
                      {/* {randomGeneralErrors.redirection_params && (
                                        <ErrorText>
                                            {randomGeneralErrors.redirection_params[index]?.value}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </RightGridItem>
                </Grid>
              ))}
            {randomGeneralUserUpdate.cta2.redirection_type === "in_app" && (
              <div>
                <Button style={{ flex: 1 }} onClick={addParamsFunc2}>
                  Add param
                </Button>
                <Button
                  style={{ marginLeft: "3%" }}
                  onClick={removeParamsFunc2}
                >
                  Remove param
                </Button>
              </div>
            )}
            </div>
            <StyledDiv>
              <LabelInput>Category</LabelInput>
              <Input
                placeholder="Category"
                name="category"
                value={randomGeneralUserUpdate?.category}
                onChange={(e) =>
                  setRandomGeneralUserUpdate({
                    ...randomGeneralUserUpdate,
                    category: e.target.value,
                  })
                }
              />
              {randomGeneralErrors?.category && (
                <ErrorText>{randomGeneralErrors?.category}</ErrorText>
              )}
            </StyledDiv>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
            >
              {/* <div>
                <LabelInput>Upload Image</LabelInput>
                <StyledDiv>
                  <Image
                    langKey={"en"}
                    value={randomGeneralUserUpdate.image}
                    onChange={(e) => onFileChange(e, "randomUserUpdate")}
                  />
                  {randomGeneralErrors.image && (
                    <ErrorText>{randomGeneralErrors.image}</ErrorText>
                  )}
                </StyledDiv>
              </div> */}
            
              <div>
                <LabelInput>Upload File</LabelInput>
                {randomGeneralUserUpdate.file.name && <LabelInput>{randomGeneralUserUpdate.file.name}</LabelInput>}

                <StyledDiv>
                  {!loading && (
                    <input
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      className={classes.input}
                      ref={fileuploadref}
                      id={"Fileupload"}
                      onChange={handleFileInputChange}
                      type="file"
                    />
                  )}
                  <ButtonUpload
                    onClick={() => {
                      fileuploadref.current.click();
                    }}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    color="primary"
                    component="span"
                  >
                    {randomGeneralUserUpdate.file ? "Replace" : "Upload"}
                  </ButtonUpload>
                  {randomGeneralErrors.file && (
                    <ErrorText>{randomGeneralErrors.file}</ErrorText>
                  )}
                </StyledDiv>
              </div>
            </div>

          </StyleModal>
        )}
      </Grid>
      <Grid>
        {showCreateModal && (
          <StyleModal
            title="Add Update"
            visible={showCreateModal}
            onOk={(e) => handleCreateBannerSubmit(e)}
            onCancel={() => handleCreateBannerModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleCreateBannerSubmit(e)}
                loading={loading}
              >
                Submit
              </Button>,
            ]}
          >
            <StyledDiv>
              <LabelInput>Text</LabelInput>
              <Input
                placeholder="text"
                name="text"
                value={currentBanner.text}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    text: e.target.value,
                  })
                }
              />
              {errors.text && <ErrorText>{errors.text}</ErrorText>}
            </StyledDiv>
            {/* <StyledDiv>
              <LabelInput>Author</LabelInput>
              <Input
                placeholder="Author"
                name="Author"
                value={currentBanner.author}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    auhtor: e.target.value,
                  })
                }
              />
              {errors.author && <ErrorText>{errors.author}</ErrorText>}
            </StyledDiv> */}
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Target Users</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "target_users", "create")
                    }
                    value={currentBanner.target_users}
                  >
                    {TargetUsers && createOptionList(TargetUsers.data, "create")}
                  </Select>
                  {errors.target_users && (
                    <ErrorText>{errors.target_users}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Type</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => handleSelectChange(e, "type", "create")}
                    value={currentBanner.type}
                  >
                    {Type && createOptionList(Type.data)}
                  </Select>
                  {errors.target_users && <ErrorText>{errors.type}</ErrorText>}
                </StyledDiv>
              </RightGridItem>
            </Grid>
            <StyledDiv>
              <LabelInput>Is Active</LabelInput>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => handleSelectChange(e, "is_active", "create")}
                value={currentBanner.is_active}
              >
                {IsActive && createOptionList(IsActive.data)}
              </Select>
              {errors.is_active && <ErrorText>{errors.is_active}</ErrorText>}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Left CTA</LabelInput>
              <Input
                placeholder="cta-1"
                name="Cta-1"
                value={currentBanner.cta1.text}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    cta1: {
                      ...currentBanner.cta1,
                      text: e.target.value,
                    },
                  })
                }
              />
              {errors?.cta1?.text && (
                <ErrorText>{errors?.cta1?.text}</ErrorText>
              )}
            </StyledDiv>
            {currentBanner.cta1.text != "" && (
              <StyledDiv>
                <LabelInput>Redirection Type</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_type-1", "create")
                  }
                  value={currentBanner.cta1.redirection_type}
                >
                  {RedirectionType && createOptionList(RedirectionType.data)}
                </Select>
                {errors?.cta1?.redirection_type && (
                  <ErrorText>{errors?.cta1?.redirection_type}</ErrorText>
                )}
              </StyledDiv>
            )}
            {currentBanner?.cta1?.redirection_type === "in_app" && (
              <StyledDiv>
                <LabelInput>Redirection In App</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_in_app-1", "create")
                  }
                  value={currentBanner.cta1.redirection_url}
                >
                  {RedirectionInApp && createOptionList(RedirectionInApp.data)}
                </Select>
                {errors?.cta1?.redirection_url && (
                  <ErrorText>{errors?.cta1?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {currentBanner?.cta1?.redirection_type === "external_link" && (
              <StyledDiv>
                <LabelInput>Redirection URL</LabelInput>
                <Input
                  placeholder="Redirection URL"
                  name="redirection_url"
                  value={currentBanner.cta1.redirection_url}
                  onChange={(e) =>
                    setCurrentBanner({
                      ...currentBanner,
                      cta1: {
                        ...currentBanner.cta1,
                        redirection_url: e.target.value,
                      },
                    })
                  }
                />
                {errors?.cta1?.redirection_url && (
                  <ErrorText>{errors?.cta1?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {currentBanner?.cta1?.redirection_type === "in_app" && (
              <LabelInput>Redirection Params</LabelInput>
            )}
            {currentBanner?.cta1?.redirection_type === "in_app" &&
              redirectionParams.map((item, index) => (
                <Grid container>
                  <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Key {index + 1}</LabelInput>
                      <Input
                        placeholder={`key ${index + 1}`}
                        name="key"
                        value={item.key}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].key = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.key}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </LeftGridItem>
                  <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Value {index + 1}</LabelInput>
                      <Input
                        placeholder={`value ${index + 1}`}
                        name="value"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].value = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.value}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </RightGridItem>
                </Grid>
              ))}
            {currentBanner.cta1.redirection_type === "in_app" && (
              <div>
                <Button style={{ flex: 1 }} onClick={addParamsFunc}>
                  Add param
                </Button>
                <Button style={{ marginLeft: "3%" }} onClick={removeParamsFunc}>
                  Remove param
                </Button>
              </div>
            )}
            <StyledDiv>
              <LabelInput>Right CTA</LabelInput>
              <Input
                placeholder="cta-2"
                name="Cta-2"
                value={currentBanner.cta2.text}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    cta2: {
                      ...currentBanner.cta2,
                      text: e.target.value,
                    },
                  })
                }
              />
              {errors?.cta2?.text && (
                <ErrorText>{errors?.cta2?.text}</ErrorText>
              )}
            </StyledDiv>
            {currentBanner.cta2.text != "" && (
              <StyledDiv>
                <LabelInput>Redirection Type</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_type-2", "create")
                  }
                  value={currentBanner.cta2.redirection_type}
                >
                  {RedirectionType && createOptionList(RedirectionType.data)}
                </Select>
                {errors?.cta2?.redirection_type && (
                  <ErrorText>{errors?.cta2?.redirection_type}</ErrorText>
                )}
              </StyledDiv>
            )}
            {currentBanner.cta2.redirection_type === "in_app" && (
              <StyledDiv>
                <LabelInput>Redirection In App</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_in_app-2", "create")
                  }
                  value={currentBanner.cta2.redirection_url}
                >
                  {RedirectionInApp && createOptionList(RedirectionInApp.data)}
                </Select>
                {errors?.cta2?.redirection_url && (
                  <ErrorText>{errors?.cta2?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {currentBanner.cta2.redirection_type === "external_link" && (
              <StyledDiv>
                <LabelInput>Redirection URL</LabelInput>
                <Input
                  placeholder="Redirection URL"
                  name="redirection_url"
                  value={currentBanner.cta2.redirection_url}
                  onChange={(e) =>
                    setCurrentBanner({
                      ...currentBanner,
                      cta2: {
                        ...currentBanner.cta2,
                        redirection_url: e.target.value,
                      },
                    })
                  }
                />
                {errors?.cta2?.redirection_url && (
                  <ErrorText>{errors?.cta2?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {currentBanner.cta2.redirection_type === "in_app" && (
              <LabelInput>Redirection Params</LabelInput>
            )}
            {currentBanner.cta2.redirection_type === "in_app" &&
              redirectionParams2.map((item, index) => (
                <Grid container>
                  <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Key {index + 1}</LabelInput>
                      <Input
                        placeholder={`key ${index + 1}`}
                        name="key"
                        value={item.key}
                        onChange={(e) => {
                          const newArr = [...redirectionParams2];
                          newArr[index].key = e.target.value;
                          setRedirectionParams2(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.key}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </LeftGridItem>
                  <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Value {index + 1}</LabelInput>
                      <Input
                        placeholder={`value ${index + 1}`}
                        name="value"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].value = e.target.value;
                          setRedirectionParams2(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.value}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </RightGridItem>
                </Grid>
              ))}
            {currentBanner.cta2.redirection_type === "in_app" && (
              <div>
                <Button style={{ flex: 1 }} onClick={addParamsFunc2}>
                  Add param
                </Button>
                <Button
                  style={{ marginLeft: "3%" }}
                  onClick={removeParamsFunc2}
                >
                  Remove param
                </Button>
              </div>
            )}
            {/* <LabelInput>Upload Image</LabelInput>

            <StyledDiv>
              <Image
                langKey={"en"}
                value={currentBanner.banner_image}
                onChange={(e) => onFileChange(e, "create")}
              />
              {errors.banner_image && (
                <ErrorText>{errors.banner_image}</ErrorText>
              )}
            </StyledDiv> */}
          </StyleModal>
        )}
      </Grid>
      <Grid>
        {showUpdateModal && (
          <StyleModal
            title="Edit Update"
            visible={showUpdateModal}
            onOk={(e) => handleUpdateBannerSubmit(e)}
            onCancel={() => handleUpdateModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => {
                  console.log(
                    "🚀 ~ file: index.jsx:1831 ~ updateBanner:",
                    updateBanner
                  );
                  handleUpdateBannerSubmit(e);
                }}
                loading={loading}
              >
                Update
              </Button>,
            ]}
          >
            <StyledDiv>
              <LabelInput>text</LabelInput>
              <Input
                placeholder="text"
                name="text"
                value={updateBanner.text}
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    text: e.target.value,
                  })
                }
              />
              {updateErrors.text && <ErrorText>{updateErrors.text}</ErrorText>}
            </StyledDiv>

            <StyledDiv>
              <LabelInput>Left CTA</LabelInput>
              <Input
                placeholder="Left CTA"
                name="Cta-1"
                value={updateBanner?.cta1?.text}
                onChange={(e) => {
                  setUpdateBanner({
                    ...updateBanner,
                    cta1: {
                      ...updateBanner.cta1,
                      text: e.target.value,
                    },
                  });
                }}
              />
              {errors?.cta1?.text && (
                <ErrorText>{errors?.cta1?.text}</ErrorText>
              )}
            </StyledDiv>

            {updateBanner?.cta1?.text != "" &&
              updateBanner?.cta1?.text != undefined && (
                <StyledDiv>
                  <LabelInput>Redirection Type</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "redirection_type-1", "update")
                    }
                    value={updateBanner?.cta1?.redirection_type}
                  >
                    {RedirectionType && createOptionList(RedirectionType.data)}
                  </Select>
                  {errors?.cta1?.redirection_type && (
                    <ErrorText>{errors?.cta1?.redirection_type}</ErrorText>
                  )}
                </StyledDiv>
              )}

            {updateBanner?.cta1?.redirection_type === "in_app" && (
              <StyledDiv>
                <LabelInput>Redirection In App</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_in_app-1", "update")
                  }
                  value={updateBanner?.cta1?.redirection_url}
                >
                  {RedirectionInApp && createOptionList(RedirectionInApp.data)}
                </Select>
              </StyledDiv>
            )}
            {updateBanner?.cta1?.redirection_type === "external_link" && (
              <StyledDiv>
                <LabelInput>Redirection URL</LabelInput>
                <Input
                  placeholder="Redirection URL"
                  name="redirection_url"
                  value={updateBanner?.cta1?.redirection_url}
                  onChange={(e) =>
                    setUpdateBanner({
                      ...updateBanner,
                      cta1: {
                        ...updateBanner.cta1,
                        redirection_url: e.target.value,
                      },
                    })
                  }
                />
              </StyledDiv>
            )}
            {updateBanner?.cta1?.redirection_type === "in_app" && (
              <LabelInput>Redirection Params</LabelInput>
            )}
            {updateBanner?.cta1?.redirection_type === "in_app" &&
              redirectionParams.map((item, index) => (
                <Grid container>
                  <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Key {index + 1}</LabelInput>
                      <Input
                        placeholder={`key ${index + 1}`}
                        name="key"
                        value={item.key}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].key = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.key}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </LeftGridItem>
                  <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Value {index + 1}</LabelInput>
                      <Input
                        placeholder={`value ${index + 1}`}
                        name="value"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].value = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.value}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </RightGridItem>
                </Grid>
              ))}
            {updateBanner?.cta1?.redirection_type === "in_app" && (
              <div>
                <Button style={{ flex: 1 }} onClick={addParamsFunc}>
                  Add param
                </Button>
                <Button style={{ marginLeft: "3%" }} onClick={removeParamsFunc}>
                  Remove param
                </Button>
              </div>
            )}

            {updateBanner?.cta1?.text != "" && updateBanner?.cta1?.text && (
              <StyledDiv>
                <LabelInput>Right CTA</LabelInput>
                <Input
                  placeholder="Right CTA"
                  name="Cta-2"
                  value={updateBanner?.cta2?.text}
                  onChange={(e) => {
                    setUpdateBanner({
                      ...updateBanner,
                      cta2: {
                        ...updateBanner.cta2,
                        text: e.target.value,
                      },
                    });
                  }}
                />
                {errors?.cta2?.text && (
                  <ErrorText>{errors?.cta2?.text}</ErrorText>
                )}
              </StyledDiv>
            )}

            {updateBanner?.cta2 &&
              updateBanner?.cta2?.text != "" &&
              updateBanner?.cta2?.text != undefined && (
                <StyledDiv>
                  <LabelInput>Redirection Type</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "redirection_type-2", "update")
                    }
                    value={updateBanner?.cta2?.redirection_type}
                  >
                    {RedirectionType && createOptionList(RedirectionType.data)}
                  </Select>
                  {errors?.cta2?.redirection_type && (
                    <ErrorText>{errors?.cta2?.redirection_type}</ErrorText>
                  )}
                </StyledDiv>
              )}
            {updateBanner?.cta2?.redirection_type === "in_app" && (
              <StyledDiv>
                <LabelInput>Redirection In App</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_in_app-2", "update")
                  }
                  value={updateBanner?.cta2?.redirection_url}
                >
                  {RedirectionInApp && createOptionList(RedirectionInApp.data)}
                </Select>
              </StyledDiv>
            )}
            {updateBanner?.cta2?.redirection_type === "external_link" && (
              <StyledDiv>
                <LabelInput>Redirection URL</LabelInput>
                <Input
                  placeholder="Redirection URL"
                  name="redirection_url"
                  value={updateBanner?.cta2?.redirection_url}
                  onChange={(e) =>
                    setUpdateBanner({
                      ...updateBanner,
                      cta2: {
                        ...updateBanner.cta2,
                        redirection_url: e.target.value,
                      },
                    })
                  }
                />
                {errors?.cta2?.redirection_url && (
                  <ErrorText>{errors?.cta2?.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {updateBanner?.cta2?.redirection_type === "in_app" && (
              <LabelInput>Redirection Params</LabelInput>
            )}
            {updateBanner?.cta2?.redirection_type === "in_app" &&
              redirectionParams2.map((item, index) => (
                <Grid container>
                  <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Key {index + 1}</LabelInput>
                      <Input
                        placeholder={`key ${index + 1}`}
                        name="key"
                        value={item.key}
                        onChange={(e) => {
                          const newArr = [...redirectionParams2];
                          newArr[index].key = e.target.value;
                          setRedirectionParams2(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.key}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </LeftGridItem>
                  <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Value {index + 1}</LabelInput>
                      <Input
                        placeholder={`value ${index + 1}`}
                        name="value"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].value = e.target.value;
                          setRedirectionParams2(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.value}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </RightGridItem>
                </Grid>
              ))}
            {updateBanner?.cta2?.redirection_type === "in_app" && (
              <div>
                <Button style={{ flex: 1 }} onClick={addParamsFunc2}>
                  Add param
                </Button>
                <Button
                  style={{ marginLeft: "3%" }}
                  onClick={removeParamsFunc2}
                >
                  Remove param
                </Button>
              </div>
            )}
            { updateBanner.target_users !== "selected" &&
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Target Users</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "target_users", "update")
                    }
                    value={updateBanner.target_users}
                  >
                    {TargetUsers && createOptionList(TargetUsers.data, updateBanner.target_users !== "selected" ? "create": null)}
                  </Select>
                  {updateErrors.target_users && (
                    <ErrorText>{updateErrors.target_users}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Type</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => handleSelectChange(e, "type", "update")}
                    value={updateBanner.type}
                  >
                    {Type && createOptionList(Type.data)}
                  </Select>
                  {updateErrors.target_users && (
                    <ErrorText>{updateErrors.type}</ErrorText>
                  )}
                </StyledDiv>
              </RightGridItem>
            </Grid>}
            { updateBanner.target_users === "selected" &&
            <StyledDiv>
              <LabelInput>Category</LabelInput>
              <Input
                placeholder="Category"
                name="category"
                value={updateBanner?.category}
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    category: e.target.value,
                  })
                }
              />
              {updateErrors?.category && (
                <ErrorText>{updateErrors?.category}</ErrorText>
              )}
            </StyledDiv>}

            {updateBanner.target_users == "selected" && (
              <StyledDiv>
                <LabelInput>Mobile Number</LabelInput>
                <Input
                  placeholder="Mobile Number"
                  name="Mobile Number"
                  type="number"
                  max={10}
                  value={updateBanner?.mobile_number}
                  onChange={(e) => {
                    setUpdateBanner({
                      ...updateBanner,
                      mobile_number: e.target.value,
                    });
                  }}
                />
                {errors?.mobile_number && (
                  <ErrorText>{errors?.mobile_number}</ErrorText>
                )}
              </StyledDiv>
            )}
            <StyledDiv>
              <LabelInput>Is Active</LabelInput>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => handleSelectChange(e, "is_active", "update")}
                value={updateBanner.is_active}
              >
                {IsActive && createOptionList(IsActive.data)}
              </Select>
              {updateErrors.is_active && (
                <ErrorText>{updateErrors.is_active}</ErrorText>
              )}
            </StyledDiv>
            {/* <LabelInput>Update Image</LabelInput> */}
            {/* <Image
              value={updateBanner.banner_image}
              onChange={(e) => onFileChange(e, "update")}
            /> */}

            {/* <StyledDiv>
              <Image
                langKey={"en"}
                update={true}
                value={updateBanner.banner_image}
                onChange={(e) => onFileChange(e, "update")}
              />
              {updateErrors.banner_image && (
                <ErrorText>{updateErrors.banner_image}</ErrorText>
              )}
            </StyledDiv> */}
          </StyleModal>
        )}
      </Grid>
    </Grid>
  );
};

export default GeneralUpdate;
